<template>
	<ProcessingLayout>
		<template #content>
			<div class="cassie-vertical-md">
				<div class="d-flex align-center cassie-horizontal-md">
					<SecondaryActionButton
						:input-value="showFilters"
						@click="showFilters = !showFilters"
					>
						<v-icon>
							mdi-filter
						</v-icon>
						Filter
					</SecondaryActionButton>
					<template v-if="showFilters">
						<TextField
							v-model="searchQuery"
							label="Process Name"
							append-icon="mdi-magnify"
						/>
						<Dropdown
							v-model="selectedBrandId"
							:label="brandLabel"
							:items="brandOptions"
							custom-sort
						/>
					</template>
					<v-spacer />
					<PrimaryActionButton @click="onCreateClick">
						<v-icon
							left
							dark
						>
							mdi-plus
						</v-icon>
						Create
					</PrimaryActionButton>
				</div>
				<SectionCard>
					<template #title>
						Processes
					</template>
					<template #body>
						<DataTable
							:headers="tableHeaders"
							:items="filteredProcesses"
							@click:row="onEditProcess"
						>
							<template #item.createdDate="{ item }">
								{{ format(new Date(item.createdDate), DATE_FORMAT) }}
							</template>
							<template #item.action="{ item }">
								<IconButton
									tooltip-text="Edit Process"
									@click="onEditProcess(item)"
								>
									mdi-pencil
								</IconButton>
								<IconButton
									tooltip-text="Delete Process"
									@click.stop.prevent="onRemoveProcess(item)"
								>
									mdi-trash-can
								</IconButton>
							</template>
						</DataTable>
					</template>
				</SectionCard>
				<ConfirmDeleteModal
					v-if="processToBeRemoved"
					:entity-name="processToBeRemoved.name"
					entity-type="Process"
					@close="processToBeRemoved = null"
					@delete="deleteProcess"
				/>
			</div>
		</template>
	</ProcessingLayout>
</template>
<script>
import { format } from 'date-fns'
import { DATE_FORMAT } from '../../../../../shared/utils/date-formatting.js'
import ProcessingLayout from './processing-layout.vue'
import ConfirmDeleteModal from '../../../../../shared/components/confirm-delete-modal.vue'
import { brandOptions } from '../../../../../shared/state/brands.js'
import { deleteProcess, getProcessesAndLookupData } from '../../../../../shared/utils/api/processing.js'
import { showSnackbar } from '../../../../../shared/state/snackbar.js'
import SecondaryActionButton from '../../../../../shared/components/secondary-action-button.vue'
import IconButton from '../../../../../shared/components/icon-button.vue'
import { useLabels } from '../../../../../shared/state/admin-portal-navigation.js'
import TextField from '../../../../../shared/components/text-field.vue'
import Dropdown from '../../../../../shared/components/dropdown.vue'
import PrimaryActionButton from '../../../../../shared/components/primary-action-button.vue'
import SectionCard from '../../../../../shared/components/section-card.vue'
import DataTable from '../../../../../shared/components/data-table.vue'
import { PROCESSING_MANAGE_PROCESS } from '../../../router/route-names.js'
export default {
	components: {
		DataTable,
		SectionCard,
		PrimaryActionButton,
		Dropdown,
		TextField,
		SecondaryActionButton,
		ConfirmDeleteModal,
		ProcessingLayout,
		IconButton
	},
	setup () {
		return {

			brandOptions,
			format,
			DATE_FORMAT
		}
	},
	data () {
		return {
			showFilters: false,
			searchQuery: null,
			processToBeRemoved: null,
			selectedBrandId: null,
			processes: [],
			tableHeaders: [
				{
					value: 'processId',
					text: 'ID',
					width: '10%'
				},
				{
					value: 'name',
					text: 'Process Name',
					width: '10%'
				},
				{
					value: 'processPurposeName',
					text: 'Purpose',
					width: '10%'
				},
				{
					value: 'brandName',
					text: useLabels('Brand'),
					width: '10%'
				},
				{
					value: 'ownerName',
					text: 'Owner',
					width: '10%'
				},
				{
					value: 'cityName',
					text: 'City',
					width: '10%'
				},
				{
					value: 'sourceSystemName',
					text: 'Data Source',
					width: '10%'
				},
				{
					value: 'destinationSystemName',
					text: 'Destination',
					width: '10%'
				},
				{
					value: 'createdDate',
					text: 'Created Date',
					width: '10%'
				},
				{
					value: 'action',
					text: 'Action',
					width: '10%',
					sortable: false
				}
			]
		}
	},
	computed: {
		filteredProcesses () {
			return this.processes.filter(({ name, brandId }) => {
				let check = true
				if (this.searchQuery) check = name.toLowerCase().includes(this.searchQuery.toLowerCase())
				if (this.selectedBrandId !== null) check = check && this.selectedBrandId === brandId
				return check
			})
		},
		brandLabel () {
			return `${useLabels('Brand')}`
		}
	},
	created () {
		this.loadProcessingData()
	},
	methods: {
		async loadProcessingData () {
			const { data: { processes } } = await getProcessesAndLookupData()
			this.processes = processes
		},
		async deleteProcess () {
			await deleteProcess(this.processToBeRemoved.processId)
			this.processToBeRemoved = null
			showSnackbar('This process has been removed')
			await this.loadProcessingData()
		},
		onCreateClick () {
			this.$router.push({
				name: PROCESSING_MANAGE_PROCESS
			})
		},
		onEditProcess (processToEdit) {
			this.$router.push({
				name: PROCESSING_MANAGE_PROCESS,
				params: {
					processToEdit
				}
			})
		},
		onRemoveProcess (item) {
			this.processToBeRemoved = item
		}
	}
}
</script>
